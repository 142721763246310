import React, { useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Students from "../students/StudentsList";
const Home = () => {
  return (
    <>
      <Students />
    </>
  );
};
export default Home;
