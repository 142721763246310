import React, { useContext, useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";

function NavBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { state, dispatch } = useContext(UserContext);

  const handleLogout = () => {
    dispatch({ type: "USER", payload: false });
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleButtonClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <>
        <div className=" w-full h-20 bg-white flex justify-start fixed top-0">
          {state && (
            <Bars3Icon
              className="block h-full w-6 mx-5 cursor-pointer"
              onClick={handleButtonClick}
            />
          )}
          <h1
            className={`font-bold text-xl ${
              !state && "ml-3"
            } text-black h-full block mt-6`}
          >
            Reteach Admin Panel
          </h1>
        </div>
        {isSidebarOpen && (
          <div
            className="fixed top-20 left-0 z-40 w-64 h-screen p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-800"
            tabIndex="-1"
          >
            <h5
              id="drawer-navigation-label"
              className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
            >
              Menu
            </h5>
            <button
              type="button"
              data-drawer-hide="drawer-navigation"
              aria-controls="drawer-navigation"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleButtonClick}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                ></path>
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
            <div className="py-4 overflow-y-auto">
              <ul className="space-y-2 font-medium">
                <li>
                  <Link
                    to="/home"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">Home</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/payments"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Payments
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Add-class"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Add Standard
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/standard-list"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Standards List
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/coupen-list"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Manage Coupens
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Add Course
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/courses"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Courses list
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/students"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Students
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/enquiry"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Enquires
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={handleLogout}
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Logout
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default NavBar;
