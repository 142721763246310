import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ClipLoader } from "react-spinners";

export default function AddNewStandard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <div className=" max-w-7xl mx-auto rounded-lg bg-gray-900 px-6 lg:py-4 mt-20 lg:px-8 sm:py-4">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader
            className=" items-center w-full h-full"
            color="#FFFFFF"
            loading={loading}
            size={50}
          />
        </div>
      ) : (
        <>
          <div className=" flex justify-center items-center">
            <p className=" text-white font-medium text-2xl py-5">
              Add New Standard
            </p>
          </div>
          <form
            noValidate
            className="bg-gray-900 mx-auto mt-5 max-w-xl sm:mt-6"
            onSubmit={handleSubmit(async (data) => {
              setLoading(true);
              const data1 = new FormData();
              data1.append("className", data.standard);
              data1.append("classPrice", data.regularPrice);
              data1.append("classDiscountPrice", data.DiscountedPrice);
              data1.append("class_englishMediumPrice", data.engMediumPrice);
              data1.append(
                "class_englishMediumDiscountPrice",
                data.engDiscountedPrice
              );
              data1.append("class_hindiMediumPrice", data.hindiMediumPrice);
              data1.append(
                "class_hindiMediumDiscountPrice",
                data.hindiDiscountedPrice
              );
              console.log(data);
              const config = {
                headers: { "content-type": "multipart/form-data" },
              };
              axios
                .post(
                  "https://lms-backend-ln7x.onrender.com/api/class/register-class",
                  data1,
                  config
                )
                .then((response) => {
                  console.log(response);
                  reset();
                  setLoading(false);
                  alert("Standard is uploaded");
                })
                .catch((error) => {
                  console.log(error);
                  setError(error);
                  setLoading(false);
                });
            })}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Standard Name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="standard"
                    {...register("standard", {
                      required: "Standard name is required",
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.standard && (
                    <p className=" text-start text-red-500">
                      {errors.standard.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Course Regular Price */}
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Standard Regular Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="regularPrice"
                    id="first-name"
                    {...register("regularPrice", {
                      required: "Regular Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.regularPrice && (
                    <p className=" text-start text-red-500">
                      {errors.regularPrice.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Standard Discounted Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="course DiscountedPrice"
                    {...register("DiscountedPrice", {
                      required: "Standard Discounted Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.DiscountedPrice && (
                    <p className=" text-start text-red-500">
                      {errors.DiscountedPrice.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Course Eng med price */}
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  English Medium Regular Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    {...register("engMediumPrice", {
                      required: "English Medium Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.engMediumPrice && (
                    <p className=" text-start text-red-500">
                      {errors.engMediumPrice.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  English Medium Discounted Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="course engDiscountedPrice"
                    {...register("engDiscountedPrice", {
                      required: "English Medium Discounted Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.engDiscountedPrice && (
                    <p className=" text-start text-red-500">
                      {errors.engDiscountedPrice.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Course hindi med price */}
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Hindi Medium Regular Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    {...register("hindiMediumPrice", {
                      required: "Hindi Medium Regular Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.hindiMediumPrice && (
                    <p className=" text-start text-red-500">
                      {errors.hindiMediumPrice.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Hindi Medium Discounted Price
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="course DiscountedPrice"
                    {...register("hindiDiscountedPrice", {
                      required: "Hindi Medium Discounted Price is required",
                      pattern: {
                        value: /(^\d{1,10}$)/g,
                        message: "Enter Valid Price",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.hindiDiscountedPrice && (
                    <p className=" text-start text-red-500">
                      {errors.hindiDiscountedPrice.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {error && <p className="text-red-500">{error || error.message}</p>}
            <div className="mt-10 mb-4">
              <button
                type="submit"
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add Standard
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

// {selectedFile && (
//   <div>
//     <p className="font-bold">Selected File:</p>
//     <p>{selectedFile.name}</p>
//     <p>{selectedFile.type}</p>
//     <p>{selectedFile.size} bytes</p>
//   </div>
// )}
