import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import Certificate from "./components/certificates/Certificate";
import Payments from "./components/payment/Payments";
import AddCourse from "./components/course/AddCourse";
import CoursesList from "./components/course/coursesList";
import StudentsList from "./components/students/StudentsList";
import Enquiries from "./components/enquiry/Enquiries";
import AddNewStandard from "./components/standard/AddNewStandard";
import NavBar from "./components/NavBar/NavBar";
import { createContext, useReducer } from "react";
import { initialState, reducer } from "./components/useReducer/Reducer";
import Protected from "./components/protected/Protected";
import UserCourses from "./components/students/UserCourses";
import StandardList from "./components/standard/StandardList";
import CoupenList from "./components/coupen-code/CoupenList";
import AddCoupen from "./components/coupen-code/AddCoupen";
import NotFoundPage from "./components/404/NotFoundPage";

export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <>
      <UserContext.Provider value={{ state, dispatch }}>
        <Router>
          <NavBar></NavBar>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/home"
              element={
                <Protected>
                  <Home />
                </Protected>
              }
            />
            <Route
              path="/certificate"
              element={
                <Protected>
                  <Certificate />
                </Protected>
              }
            />
            <Route
              path="/payments"
              element={
                <Protected>
                  <Payments />
                </Protected>
              }
            />
            <Route
              path="/add"
              element={
                <Protected>
                  <AddCourse />
                </Protected>
              }
            />
            <Route
              path="/courses"
              element={
                <Protected>
                  <CoursesList />
                </Protected>
              }
            />
            <Route
              path="/students"
              element={
                <Protected>
                  <StudentsList />
                </Protected>
              }
            />
            <Route
              path="/enquiry"
              element={
                <Protected>
                  <Enquiries />
                </Protected>
              }
            />
            <Route
              path="/Add-class"
              element={
                <Protected>
                  <AddNewStandard />
                </Protected>
              }
            />
            <Route
              path="/purchased-courses/:id"
              element={
                <Protected>
                  <UserCourses />
                </Protected>
              }
            />
            <Route
              path="/standard-list"
              element={
                <Protected>
                  <StandardList />
                </Protected>
              }
            />
            <Route
              path="/add-coupen"
              element={
                <Protected>
                  <AddCoupen />
                </Protected>
              }
            />
            <Route
              path="/coupen-list"
              element={
                <Protected>
                  <CoupenList />
                </Protected>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </UserContext.Provider>
    </>
  );
}

export default App;
