import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { MdEdit } from "react-icons/md";
import PopupYesNo from "../togglePopup/PopupYesNo";
import EditStandard from "./EditStandard";
import { Link } from "react-router-dom";

export default function StandardList() {
  const [standardList, setStandardList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [restart, setRestart] = useState(false);
  const [selectedStandardId, setSelectedStandardId] = useState(null);

  const cancelButtonRef = useRef(null);
  const cancelButtonRefEdit = useRef(null);

  const handleDelete = () => {
    setOpen(true);
  };
  const handleEdit = (standardId) => {
    setSelectedStandardId(standardId);
    setOpenEdit(true);
  };
  useEffect(() => {
    handleStandardList();
  }, []);

  const handleStandardList = () => {
    axios
      .get("https://lms-backend-ln7x.onrender.com/api/class/classes-list")
      .then((response) => {
        setStandardList(response.data.classes);
        // console.log(standardList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className=" w-full h-full rounded-md">
        <div className=" flex flex-col">
          <div className="flex items-center justify-center h-20 bg-white rounded-md">
            <p className="text-center text-black font-semibold text-lg">
              Standard List
            </p>
          </div>

          <div className="">
            <div className="mx-auto items-center max-w-7xl px-10 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
              <h2 className="sr-only text-white">Standards</h2>
              <div className=" grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-x-8 items-center">
                <EditStandard
                  standardList={standardList}
                  standardId={selectedStandardId}
                  open={openEdit}
                  setOpen={setOpenEdit}
                  cancelButtonRef={cancelButtonRefEdit}
                  setRestart={setRestart}
                  restart={restart}
                />
                <PopupYesNo
                  open={open}
                  handleStandardList={handleStandardList}
                  setOpen={setOpen}
                  cancelButtonRef={cancelButtonRef}
                  standardId={selectedStandardId}
                />
                {standardList.map((e) => (
                  <>
                    <div key={e._id} href="" className="group">
                      <div className=" bg-white mx-0 pb-4 h-auto flex flex-col">
                        <div className=" flex flex-row justify-around mx-2">
                          {/* <Link to="/edit-standard"> */}
                          <div
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-max ml-3 rounded"
                            onClick={() => handleEdit(e._id)}
                          >
                            Edit Standard
                          </div>
                          {/* </Link> */}
                          <div
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 w-max ml-3 rounded cursor-pointer"
                            onClick={handleDelete}
                          >
                            Delete Standard
                          </div>
                        </div>
                        <PopupYesNo
                          open={open}
                          handleStandardList={handleStandardList}
                          setOpen={setOpen}
                          cancelButtonRef={cancelButtonRef}
                          standardId={e._id}
                        />
                        <div className=" flex flex-row justify-around mx-2">
                          <h3 className="mt-4 text-sm text-black">
                            Class Name: {e.className}
                          </h3>
                        </div>

                        <div className=" flex flex-row justify-around mx-2">
                          <h3 className="mt-4 text-sm text-black">
                            Regular Price: {e.classPrice}
                            {" ₹"}
                          </h3>
                          <h3 className="mt-4 text-sm text-black">
                            Regular Discounted Price: {e.classDiscountPrice}
                            {" ₹"}
                          </h3>
                        </div>
                        <div className=" flex flex-row justify-around mx-2">
                          <h3 className="mt-4 text-sm text-black">
                            English Medium Price: {e.class_englishMediumPrice}
                            {" ₹"}
                          </h3>
                          <h3 className="mt-4 text-sm text-black">
                            English Medium Discounted Price:{" "}
                            {e.class_englishMediumDiscountPrice}
                            {" ₹"}
                          </h3>
                        </div>
                        <div className=" flex flex-row justify-around mx-2">
                          <h3 className="mt-4 text-sm text-black">
                            Hindi Medium Price: {e.class_hindiMediumPrice}
                            {" ₹"}
                          </h3>
                          <h3 className="mt-4 text-sm text-black">
                            Hindi Medium Discounted Price:{" "}
                            {e.class_hindiMediumDiscountPrice}
                            {" ₹"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
