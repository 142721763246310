import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import DeleteCoupen from "./DeleteCoupen";
import AddCoupen from "./AddCoupen";
import EditCoupen from "./EditCoupen";
import { Link } from "react-router-dom";

export default function CoupenList() {
  const [coupenList, setcoupenList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [restart, setRestart] = useState(false);
  const [selectedStandardId, setSelectedStandardId] = useState(null);

  const cancelButtonRef = useRef(null);
  const cancelButtonRefEdit = useRef(null);

  const handleDelete = () => {
    setOpen(true);
  };

  const handleEdit = (standardId) => {
    setSelectedStandardId(standardId);
    setOpenEdit(true);
  };

  useEffect(() => {
    handlecoupenList();
  }, []);

  const handlecoupenList = () => {
    axios
      .get("https://lms-backend-ln7x.onrender.com/api/coupen-code/coupens-list")
      .then((response) => {
        setcoupenList(response.data.coupens);
        // console.log(coupenList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className=" w-full h-full rounded-md">
        <div className=" flex flex-col">
          <div className="flex items-center justify-center h-20 bg-white rounded-md"></div>

          <div className="">
            <div className="mx-auto items-center max-w-7xl px-10 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
              <Link
                to="/add-coupen"
                className="bg-green-500 mb-5 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
              >
                Add New Coupen
              </Link>
              <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-x-8 items-center">
                <EditCoupen
                  coupenList={coupenList}
                  coupenId={selectedStandardId}
                  open={openEdit}
                  setOpen={setOpenEdit}
                  cancelButtonRef={cancelButtonRefEdit}
                  setRestart={setRestart}
                  restart={restart}
                />
                <DeleteCoupen
                  open={open}
                  handlecoupenList={handlecoupenList}
                  setOpen={setOpen}
                  cancelButtonRef={cancelButtonRef}
                  standardId={selectedStandardId}
                />
                {coupenList.map((e) => (
                  <div key={e._id} href="" className="group">
                    <div className=" bg-white mx-0 pb-4 h-auto flex flex-col">
                      <div className=" flex flex-row mt-3 justify-around mx-2">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-max ml-3 rounded"
                          onClick={() => handleEdit(e._id)}
                        >
                          Edit Coupen
                        </button>
                        <div
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 w-max ml-3 rounded cursor-pointer"
                          onClick={handleDelete}
                        >
                          Delete Coupen
                        </div>
                      </div>
                      <div className=" flex flex-row justify-around mx-2">
                        <h3 className="mt-4 text-sm text-black">
                          Coupen Code: {e.coupenCode}
                        </h3>
                      </div>

                      <div className=" flex flex-row justify-around mx-2">
                        <h3 className="mt-4 text-sm text-black">
                          Coupen Amount: {e.coupenAmountPercentage}
                          {" %"}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
