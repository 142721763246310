import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ClipLoader } from "react-spinners";

export default function AddCoupen() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <div className=" max-w-7xl mx-auto rounded-lg bg-gray-900 px-6 lg:py-4 mt-20 lg:px-8 sm:py-4">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader
            className=" items-center w-full h-full"
            color="#FFFFFF"
            loading={loading}
            size={50}
          />
        </div>
      ) : (
        <>
          <div className=" flex justify-center items-center">
            <p className=" text-white font-medium text-2xl py-5">
              Add New Coupen
            </p>
          </div>
          <form
            noValidate
            className="bg-gray-900 mx-auto mt-5 max-w-xl sm:mt-6"
            onSubmit={handleSubmit(async (data) => {
              setLoading(true);
              const data1 = new FormData();
              data1.append("coupenCode", data.coupenCode);
              data1.append(
                "coupenAmountPercentage",
                data.coupenAmountPercentage
              );
              const config = {
                headers: {
                  "content-type": "multipart/form-data",
                },
              };
              axios
                .post(
                  `https://lms-backend-ln7x.onrender.com/api/coupen-code/add-coupen`,
                  data1,
                  config
                )
                .then((response) => {
                  console.log("Response=>", response);
                  reset();
                  alert("Coupen Code is Added");
                  setLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                  setError(error);
                  setLoading(false);
                });
            })}
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Coupen Name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="coupenCode"
                    {...register("coupenCode", {
                      required: "coupenCode name is required",
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.coupenCode && (
                    <p className=" text-start text-red-500">
                      {errors.coupenCode.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Coupen Percentage{" "}
                </label>
                <div className="mt-2.5">
                  <input
                    type="number"
                    name="coupenAmount"
                    {...register("coupenAmountPercentage", {
                      required: "coupen amount is required",
                      pattern: {
                        value:
                          /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,})?$)/g,
                        message: "Coupen Amount is not valid",
                      },
                    })}
                    className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.coupenAmountPercentage && (
                    <p className=" text-start text-red-500">
                      {errors.coupenAmountPercentage.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {error && <p className="text-red-500">{error || error.message}</p>}
            <div className="mt-10 mb-4">
              <button
                type="submit"
                className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Update Coupen
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

// {selectedFile && (
//   <div>
//     <p className="font-bold">Selected File:</p>
//     <p>{selectedFile.name}</p>
//     <p>{selectedFile.type}</p>
//     <p>{selectedFile.size} bytes</p>
//   </div>
// )}
