import React from 'react'

const Certificate = () => {
  return (
    <div className=' text-white'>
      Certificate
    </div>
  )
}

export default Certificate
