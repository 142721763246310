import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function EditStandard({
  standardList,
  standardId,
  open,
  setOpen,
  cancelButtonRef,
  setRestart,
  restart,
}) {
  //   const [standardDataThis, setStandardDataThis] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen  overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full w-full m-4">
                <div className="bg-gray-900 flex flex-col items-center justify-center px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  {standardList?.map((item) => {
                    if (!(item._id === standardId)) {
                      return null;
                    }
                    return (
                      <div key={item._id}>
                        <div className=" flex justify-center items-center">
                          <p className=" text-white font-medium text-2xl py-5">
                            Edit {item.className}
                          </p>
                        </div>
                        <form
                          noValidate
                          className="bg-gray-900 mx-auto mt-5 max-w-xl sm:mt-6"
                          onSubmit={handleSubmit(async (data) => {
                            const data1 = new FormData();
                            data1.append("className", data.standard);
                            data1.append("classPrice", data.regularPrice);
                            data1.append(
                              "classDiscountPrice",
                              data.DiscountedPrice
                            );
                            data1.append(
                              "class_englishMediumPrice",
                              data.engMediumPrice
                            );
                            data1.append(
                              "class_englishMediumDiscountPrice",
                              data.engDiscountedPrice
                            );
                            data1.append(
                              "class_hindiMediumPrice",
                              data.hindiMediumPrice
                            );
                            data1.append(
                              "class_hindiMediumDiscountPrice",
                              data.hindiDiscountedPrice
                            );
                            console.log(data);
                            const config = {
                              headers: {
                                "content-type": "multipart/form-data",
                              },
                            };
                            axios
                              .post(
                                `https://lms-backend-ln7x.onrender.com/api/class/classes/${standardId}`,
                                data1,
                                config
                              )
                              .then((response) => {
                                console.log(response);
                                reset();
                                alert("Standard is updated");
                                navigate("/home");
                                setOpen(false);
                              })
                              .catch((error) => {
                                console.log(error);
                                setError(error);
                              });
                          })}
                        >
                          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                              <label
                                htmlFor="company"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                Standard Name {"Old Value: " + item.className}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="standard"
                                  {...register("standard", {
                                    required: "Standard name is required",
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.standard && (
                                  <p className=" text-start text-red-500">
                                    {errors.standard.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* Course Regular Price */}
                            <div>
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                Standard Regular Price{" "}
                                {"Old Value: " + item.classPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="regularPrice"
                                  id="first-name"
                                  {...register("regularPrice", {
                                    required: "Regular Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.regularPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.regularPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                Standard Discounted Price{" "}
                                {"Old Value: " + item.classDiscountPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="course DiscountedPrice"
                                  {...register("DiscountedPrice", {
                                    required:
                                      "Standard Discounted Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.DiscountedPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.DiscountedPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* Course Eng med price */}
                            <div>
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                English Medium Regular Price{" "}
                                {"Old Value: " + item.class_englishMediumPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  {...register("engMediumPrice", {
                                    required:
                                      "English Medium Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.engMediumPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.engMediumPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                English Medium Discounted Price{" "}
                                {"Old Value: " +
                                  item.class_englishMediumDiscountPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="course engDiscountedPrice"
                                  {...register("engDiscountedPrice", {
                                    required:
                                      "English Medium Discounted Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.engDiscountedPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.engDiscountedPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* Course hindi med price */}
                            <div>
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                Hindi Medium Regular Price{" "}
                                {"Old Value: " + item.class_hindiMediumPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  {...register("hindiMediumPrice", {
                                    required:
                                      "Hindi Medium Regular Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800  text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.hindiMediumPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.hindiMediumPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-semibold leading-6 text-white"
                              >
                                Hindi Medium Discounted Price{" "}
                                {"Old Value: " +
                                  item.class_hindiMediumDiscountPrice}
                              </label>
                              <div className="mt-2.5">
                                <input
                                  type="text"
                                  name="course DiscountedPrice"
                                  {...register("hindiDiscountedPrice", {
                                    required:
                                      "Hindi Medium Discounted Price is required",
                                    pattern: {
                                      value: /(^\d{1,10}$)/g,
                                      message: "Enter Valid Price",
                                    },
                                  })}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-800 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.hindiDiscountedPrice && (
                                  <p className=" text-start text-red-500">
                                    {errors.hindiDiscountedPrice.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <p className="text-red-500">
                              {error || error.message}
                            </p>
                          )}
                          <div className="mt-10 mb-4">
                            <button
                              type="submit"
                              className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Update Class
                            </button>
                          </div>
                        </form>
                        <div />
                      </div>
                    );
                  })}

                  <div className="bg-gray-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Discard Changes
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EditStandard;
